<template>
  <EventManagementPage
    title="Waivers"
    :breadcrumbs="breadcrumbs"
    @load="onLoad"
  >
    <ConfirmationModal ref="confirmationModal"/>
    <h2>Waivers</h2>
    <div class="row">
      <FormulateForm ref="form" v-model="formValues" @submit="onSubmit">
        <div class="col full">
          <FormulateInput
            type="select"
            :options="waiverTypes"
            name="type"
            placeholder="- Please Select -"
            label="Select your waiver type"
            validation="required"
            @input="selectType"
            :disabled="disabled"
          />
        </div>
        <div class="col full">
          <FormulateInput
            label="Custom Title"
            type="text"
            placeholder="Ex: Cancellation Terms"
            name="title"
            validation="required"
          />
        </div>

        <div class="info-container" v-if="languages.count === 0">
          <div class="col full">
            <FormulateInput
              type="wysiwyg"
              name="info"
              :inputHeight="400"
              autocomplete="false"
              label="Waiver Text"
              validation="required"
            />
          </div>
        </div>
        <template v-else>
          <div
            class="translate-box"
            v-for="(translate, index) in translates"
            :key="index"
            :id="`translate-${index}`"
          >
            <div class="col full">
              <FormulateInput
                type="selectSearch"
                label="Language"
                placeholder="Select Language"
                validation="required"
                :options="filterNonselectedTranslateLanguages"
                v-model="translate.language"
                :disabled="translate.language === 'English'"
              />
            </div>
            <div class="col full">
              <FormulateInput
                type="wysiwyg"
                v-model="translate.info"
                :inputHeight="400"
                autocomplete="false"
                label="Waiver Text"
                validation="required"
              />
            </div>

            <FormulateInput
              type="button"
              name="Delete"
              v-if="index > 0"
              @click="removeTranslate(index)"
            />
          </div>
          <FormulateInput
            type="button"
            name="Add Another Language"
            @click="addTranslate"
          />
        </template>

        <div class="col full">
          <FormulateInput
            type="checkbox"
            name="enabled"
            autocomplete="false"
            label="I wish to enable a waiver at checkout, which will be required for each of my attendess to digitally sign. By using a pre-formatted waiver type I acknowledge that I have read through the text and accept the terms. I will not hold the website liable for any waiver claims."
          />
        </div>
        <div class="col full save-button">
          <FormulateInput
            type="submit"
            icon="plus"
            :loading="loading"
            :name="editingItem !== null ? 'Save Changes' : 'Save Waiver'"
          />
        </div>
      </FormulateForm>
    </div>
  </EventManagementPage>
</template>

<script>
import EventManagementPage from "../../components/EventManagementPage.vue";
import ConfirmationModal from "@/components/modals/ConfirmationModal.vue";

export default {
  name: "waivers",
  components: {
    EventManagementPage,
    ConfirmationModal
  },
  data() {
    return {
      eventID: null,
      editingItem: null,
      editingIndex: -1,
      exampleActive: false,
      loading: false,
      breadcrumbs: [
        ["Dashboard", "/dashboard"],
        ["Waivers", null]
      ],
      waiverTypes: [{label: "Custom", value: "custom"}],
      waiverInfos: [],
      draggableOptions: {
        animation: 200,
        group: "description",
        disabled: false,
        forceFallback: true,
        ghostClass: "ghost"
      },
      formValues: null,
      translates: [],
      languages: []
    };
  },
  computed: {
    eventUrl() {
      return this.$route.params.url;
    },
    disabled() {
      if (
        this.formValues &&
        this.formValues.hasOwnProperty("editable") &&
        !this.formValues.editable
      ) {
        return true;
      } else {
        return false;
      }
    },
    filterNonselectedTranslateLanguages() {
      return this.languages.filter(
        l => !this.translates.find(t => t.language === l)
      );
    }
  },
  methods: {
    addTranslate() {
      this.translates.push({
        language: "",
        info: ""
      });

      const index = this.translates.length - 1;

      setTimeout(() => {
        document.getElementById(`translate-${index}`).scrollIntoView({
          behavior: "smooth"
        });
      }, 100);
    },
    removeTranslate(index) {
      setTimeout(() => {
        this.translates.splice(index, 1);
      }, 100);
    },

    selectType(type) {
      let selectedType = this.waiverInfos.find(info => info.title === type);

      if (type === "custom") {
        this.formValues = {
          type: "custom"
        };
        return;
      } else if (this.formValues._id === selectedType._id) {
        return;
      } else if (selectedType) {
        this.formValues = selectedType;
        if (selectedType.translations) {
          this.translates = selectedType.translations;
        }
      }
    },
    onSubmit() {
      this.loading = true;
      this.formValues.translates = this.translates;
      if (this.formValues._id && this.formValues.previous) {
        this.$axios
          .post(`/waivers/${this.eventUrl}/create`, this.formValues)
          .then(({data}) => {
            // this.formValues = data.item;
            this.$toast(data.message, {type: "success"});
          })
          .finally(() => {
            this.loading = false;
          });
      } else if (this.formValues._id) {
        this.$axios
          .post(`/waivers/${this.eventUrl}/update`, this.formValues)
          .then(({data}) => {
            // this.formValues = data.item;
            this.$toast(data.message, {type: "success"});
          })
          .finally(() => {
            this.loading = false;
          });
      } else {
        this.$axios
          .post(`/waivers/${this.eventUrl}/create`, this.formValues)
          .then(({data}) => {
            this.formValues = data.item;
            this.$toast(data.message, {type: "success"});
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },
    onLoad({id}) {
      this.eventID = id;
    },
    getAllPrevious() {
      this.$axios
        .get(`/waivers/get-all-previous`)
        .then(({data}) => {
          let newTypes = [];
          data.items.forEach(item => {
            item.previous = true;
            newTypes.push({
              label: `${item.title} - ${item.event_name}`,
              value: item.title
            });
          });

          this.waiverTypes = [...newTypes, ...this.waiverTypes];
          this.waiverInfos = data.items;
          // this.waiverTypes = response.data;
        })
        .finally(() => {
          // this.loading = false;
        });
    },
    getCurrentWaiver() {
      this.$axios
        .post(`/waivers/${this.eventUrl}`)
        .then(response => {
          this.formValues = response.data;
          this.formValues.editing = true;

          this.languages = response.data.languages;
          this.translates = response.data.translations || [];
          if (this.languages.length > 0 && this.translates.length === 0) {
            this.translates.push({
              language: "English",
              info: response.data.info
            });
          }
        })
        .finally(() => {
          this.loading = false;
        });
    }
  },
  mounted() {
    this.getAllPrevious();
    this.getCurrentWaiver();
  }
};
</script>

<style lang="less" scoped>
.save-button {
  display: flex;
  width: 100%;
  margin-top: 20px;

  &::v-deep button {
    padding: 10px 0;
    width: 100%;
  }
}

.formulate-input[data-classification="textarea"] textarea {
  height: 250px;
}

.translate-box {
  border: 1px solid #3f444b;
  padding: 20px;
  border-radius: 10px;
  width: 100%;
  background: var(--card-background);
  margin: 10px 0 20px 0;
}
</style>
