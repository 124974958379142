<template>
  <modal
    class="alert"
    :active="active"
    :title="title"
    :closable="closable"
    v-on:close="close"
  >
    <div v-html="msg"></div>
    <template v-slot:footer>
      <diyobo-button
        v-if="closable"
        type="secondary"
        txt="Cancel"
        @click="close"
      />
      <diyobo-button type="primary" txt="Yes" v-on:click="onYes" />
      <diyobo-button type="deny" txt="No" v-on:click="onNo" />
    </template>
  </modal>
</template>

<script>
import Modal from "@/components/Modal.vue";
import DiyoboButton from "@/components/DiyoboButton.vue";

export default {
  name: "confirmation-modal",
  components: {
    Modal,
    DiyoboButton
  },
  props: {
    link: Boolean
  },
  data() {
    return {
      title: "",
      msg: "",
      active: false,
      onConfirm: null,
      closable: true
    };
  },
  methods: {
    open(args) {
      this.active = true;
      this.title = args.title === undefined ? "Heads Up" : args.title;
      this.msg = args.msg;
      this.confirm = args.confirm === undefined ? "Okay" : args.confirm;
      this.onConfirm = args.onConfirm;
      this.closable = !!args.closable;
      document.body.style.overflow = "hidden";
    },
    close() {
      this.active = false;
    },
    onYes() {
      if (this.onConfirm) this.onConfirm();
      this.close();
    },
    onNo() {
      this.close();
    }
  }
};
</script>

<style lang="less" scoped>
.alert {
  z-index: 1001;

  .modal-footer {
    button {
      margin-left: 16px;
    }
  }
}
</style>
