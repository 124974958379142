<template>
  <div>
    <div id="Content">
      <div class="shadow"></div>
      <div class="content-inner">
        <div class="content-path"></div>
        <Breadcrumbs :crumbs="breadcrumbs" />
        <!-- <div v-if="isMultiDate">
          <FormulateInput
            type="select"
            label="Event Date"
            placeholder="Select Event Date"
            :options="datesForDropdown"
            :value="date"
            @input="onSelectDate"
          />
        </div> -->
        <slot v-if="loaded" />
      </div>
    </div>
  </div>
</template>

<script>
import dayjs from "dayjs";
import Breadcrumbs from "@/components/Breadcrumbs";

export default {
  name: "EventManagementPage",
  components: {
    Breadcrumbs
  },
  props: {
    title: String,
    helpVideo: String,
    breadcrumbs: Array
  },
  data() {
    return {
      eventName: "",
      isMultiDate: false,
      dates: null,
      date: null,
      loaded: false
    };
  },
  computed: {
    eventURL() {
      return this.$route.params.url;
    },
    datesForDropdown() {
      return this.dates.map(d => ({
        label: d.start,
        value: d.id
      }));
    }
  },
  methods: {
    onSelectDate(id) {
      // the input runs this on mount with null (the default value)
      if (!id) {
        return;
      }

      const date = this.dates.find(d => d.id === id);

      this.$emit("load", {
        id: date.event_id,
        name: this.eventName,
        url: this.eventURL,
        dateID: id
      });

      this.date = id;

      this.$router.push("?date=" + id);

      this.loaded = true;
    }
  },
  created() {
    this.$store.commit("setTitle", this.title);
    this.$store.commit("setHelpVideoSrc", this.helpVideo);
    this.$axios.get(`/events/${this.eventURL}/dates`).then(response => {
      const data = response.data;

      this.eventName = data.event_name;

      // if (data.is_multidate) {
      //   this.isMultiDate = true;
      //   this.dates = data.dates.map(d => ({
      //     ...d,
      //     start: dayjs(+d.start).format("ddd, MMM DD, YYYY, hh:mm A"),
      //     end: dayjs(+d.end).format("ddd, MMM DD, YYYY, hh:mm A")
      //   }));

      //   const date = this.$route.query.date;
      //   if (date) {
      //     this.onSelectDate(date);
      //   }
      // } else {
      this.$emit("load", {
        id: data.event_id,
        name: data.event_name,
        url: this.eventURL,
        dateID: null
      });

      this.loaded = true;
      // }
    });
  }
};
</script>

<style lang="less" scoped></style>
